import { FC } from 'react';
import Clickable, { ClickableProps } from '~/app/components/Clickable';

import Text from '~/app/components/Text';
import useTheme from '~/app/lib/hooks/useTheme';

const RemoveButton: FC<{ text: string } & Omit<ClickableProps, 'children'>> = ({
  text,
  ...clickableProps
}) => {
  const theme = useTheme();

  return (
    <Clickable isInline {...clickableProps}>
      <Text color={theme.colorDanger} size="1.6rem">
        {text}
      </Text>
    </Clickable>
  );
};

export default RemoveButton;
