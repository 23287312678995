import { LayoutSlotIds } from '~/app/components/ItemPage/constants';
import {
  ItemPageLayoutDefinition,
  PageSectionDefinition,
} from '~/app/components/ItemPage/types';
import {
  AddonsConfig,
  AddonTypes,
  ItemPageCustomObject,
} from '~/app/lib/songwhipApi/types';
import { StoredItem } from '../types';

export type EditStageState = {
  item?: StoredItem;
};

export enum EditStageActionTypes {
  STAGE_ITEM = 'STAGE_ITEM',

  SET_ADDON = 'SET_ADDON',
  CLEAR_ADDON = 'CLEAR_ADDON',

  ADD_CUSTOM_OBJECT = 'ADD_CUSTOM_OBJECT',
  UPDATE_CUSTOM_OBJECT = 'CHANGE_CUSTOM_OBJECT',
  REMOVE_CUSTOM_OBJECT = 'REMOVE_CUSTOM_OBJECT',

  SET_LAYOUT_SECTIONS = 'SET_LAYOUT_SECTIONS',
  REMOVE_LAYOUT_SECTION = 'REMOVE_LAYOUT_SECTION',
  UPDATE_LAYOUT_SECTION = 'UPDATE_LAYOUT_SECTION',

  SET_LAYOUT = 'SET_LAYOUT',
}

export interface StageItemAction {
  type: EditStageActionTypes.STAGE_ITEM;
  item: StoredItem;
}

export interface SetAddonAction<TAddonType extends AddonTypes> {
  type: EditStageActionTypes.SET_ADDON;
  addonType: TAddonType;
  value: AddonsConfig[TAddonType];
}

export interface RemoveAddonAction {
  type: EditStageActionTypes.CLEAR_ADDON;
  addonType: AddonTypes;
}

export interface UpdateLayoutSectionAction {
  type: EditStageActionTypes.UPDATE_LAYOUT_SECTION;
  sectionPath: string;
  changedProps: any;
  prevLayout: ItemPageLayoutDefinition;
}

export interface RemoveLayoutSectionAction {
  type: EditStageActionTypes.REMOVE_LAYOUT_SECTION;
  sectionPath: string;
  prevLayout: ItemPageLayoutDefinition;
}

export interface SetLayoutSectionAction {
  type: EditStageActionTypes.SET_LAYOUT_SECTIONS;
  layoutSlotId: LayoutSlotIds;
  sections: PageSectionDefinition[];
}

export interface SetLayoutAction {
  type: EditStageActionTypes.SET_LAYOUT;
  layout: ItemPageLayoutDefinition;
}

export interface AddCustomObjectAction {
  type: EditStageActionTypes.ADD_CUSTOM_OBJECT;
  customObject: ItemPageCustomObject;
  id: string;
}

export interface RemoveCustomObjectAction {
  type: EditStageActionTypes.REMOVE_CUSTOM_OBJECT;
  path: string;
}

export interface UpdateCustomObjectAction {
  type: EditStageActionTypes.UPDATE_CUSTOM_OBJECT;
  path: string;
  data: ItemPageCustomObject;
}

export type EditStageAction =
  | StageItemAction
  | SetAddonAction<any>
  | RemoveAddonAction
  | UpdateLayoutSectionAction
  | RemoveLayoutSectionAction
  | SetLayoutSectionAction
  | AddCustomObjectAction
  | RemoveCustomObjectAction
  | UpdateCustomObjectAction
  | SetLayoutAction;
