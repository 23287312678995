import React, { useCallback } from 'react';

import TextInput, { TextInputProps } from '~/app/components/TextInput';
import { useI18n } from '~/app/lib/i18n';

import { AGE_REGEX } from '../constants';

export const AgeInput = React.memo(
  (props: Pick<TextInputProps, 'defaultValue'>) => {
    const { t } = useI18n();

    return (
      <TextInput
        name="age"
        testId="ageGateInput"
        placeholder={t('itemEdit.ageGateAddon.agePlaceholder')}
        debounceTimeout={0}
        toValidationMessage={useCallback(({ value }) => {
          if (!AGE_REGEX.test(value)) {
            return t('itemEdit.ageGateAddon.ageError');
          }
        }, [])}
        required
        {...props}
      />
    );
  }
);
