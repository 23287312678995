import { createSelector } from 'reselect';

import { toLocalLinks } from '~/app/lib/utils/resolveLinksForCountry';
import { selectUserCountry } from '../session/selectors';
import { StatusTypes, StoredItem, State } from '../types';
import { StoredCustomPage } from '../customPages/types';
import { ItemConfig } from '../../songwhipApi/types';
import { Links } from '~/types';

export const selectStagedItemState = <T extends StoredItem | StoredCustomPage>(
  state: State
) => ({
  status: StatusTypes.RESOLVED,
  value: state?.editStage?.item as T,
  error: undefined,
});

const createSelectProp =
  <TKey extends keyof StoredItem>(key: TKey) =>
  (state: State) =>
    state?.editStage?.item?.[key];

export const createSelectAllLinks = () => {
  return createSelector(
    createSelectProp('config'),
    createSelectProp('links'),
    createSelectProp('sourceCountry'),
    selectUserCountry,
    (
      config: ItemConfig | undefined,
      links: Links | undefined,
      sourceCountry: string | undefined,
      userCountry: string
    ): { dataPath: string; link: string; text?: string }[] => {
      const customLinks = toCustomLinkObjects({ config });

      const defaultLinks = toDefaultLinkObjects(
        { links, sourceCountry },
        userCountry
      );

      return [...defaultLinks, ...customLinks];
    }
  );
};

const toCustomLinkObjects = ({ config }: { config?: ItemConfig }) => {
  const customObjects = config?.objects;
  if (!customObjects) return [];

  return Object.keys(customObjects)
    .filter((id) => customObjects[id].type === 'link')
    .map((id) => ({
      dataPath: `$.custom.${id}`,
      link: customObjects[id].link,
      text: customObjects[id].text,
    }));
};

const toDefaultLinkObjects = (
  item: { links?: Links; sourceCountry?: string | undefined },
  userCountry: string
) => {
  const links = toLocalLinks(item, userCountry);
  if (!links) return [];

  return Object.keys(links)
    .map((id) => {
      const link = links[id];
      if (!link) return;

      return {
        dataPath: `$.item.links.${id}`,
        link: link.link,
      };
    })
    .filter(Boolean) as { dataPath: string; link: string }[];
};
