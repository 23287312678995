// @ts-ignore
// eslint-disable-next-line no-undef
const countriesByCode = preval`
  const { countries } = require('countries-list')

  const byCode = Object.keys(countries)
    .sort((key1, key2) => countries[key1].name > countries[key2].name ? 1 : -1)
    .reduce((result, code) => {
      const { name } = countries[code];

      // for <Select>
      result[code] = name;

      return result;
    }, {});

  module.exports = byCode;
` as { [countryCode: string]: string };

export const getCountryName = (code: string) => countriesByCode[code];

export default countriesByCode;
