import React, { useCallback, useRef } from 'react';

import RemoveButton from '~/app/components/ItemPage/sections/lib/RemoveButton';
import { DialogBoxHeader } from '~/app/components/DialogBox/DialogBoxHeader';
import { useItemContext } from '~/app/components/ItemPage/ItemPageContext';
import HeaderTitle from '~/app/components/PageHeader/HeaderTitle';
import { getCountryName } from '~/app/lib/utils/countriesByCode';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import Form, { FormApi } from '~/app/components/Form';
import InputLabel from '~/app/components/InputLabel';
import Clickable from '~/app/components/Clickable';
import Text from '~/app/components/Text';
import { useI18n } from '~/app/lib/i18n';
import Box from '~/app/components/Box';

import { useEditActions } from '../../../useEditActions';
import { AgeGateDialogRenderStage } from '../types';

import {
  AGE_GATE_TERRITORY_HASH,
  DEFAULT_TERRITORY_CODE,
  DEFAULT_TERRITORY_NAME,
} from '../constants';

import { AgeInput } from './AgeInput';
import { isEmptyObject } from '~/app/lib/utils/object';

export const EditStage = React.memo(
  ({
    setStage,
    paddingX,
    paddingY,
    countryCode,
  }: Parameters<AgeGateDialogRenderStage>[0] & {
    countryCode: string;
  }) => {
    const formApiRef = useRef<FormApi>(null);
    const { setAddon } = useEditActions();
    const { addons } = useItemContext();
    const { t } = useI18n();

    const addonData = addons[AddonTypes.AGE_GATE] ?? {};
    const isDefaultTerritory = countryCode === DEFAULT_TERRITORY_CODE;

    if (!countryCode || !addonData[countryCode]) {
      throw new Error(t('itemEdit.ageGateAddon.missingTerritory'));
    }

    return (
      <Box>
        <Box zIndex={2} style={{ position: 'sticky', top: 0 }}>
          <DialogBoxHeader
            renderMiddle={useCallback(
              () => (
                <Box
                  testId="ageGateEditTitle"
                  maxWidth="75%"
                  margin="0 auto"
                  fullHeight
                >
                  <HeaderTitle
                    text={`${t('app.actions.edit')} ${
                      isDefaultTerritory
                        ? DEFAULT_TERRITORY_NAME
                        : getCountryName(countryCode)
                    }`}
                  />
                </Box>
              ),
              []
            )}
            onBackClick={useCallback(() => {
              setStage('default');
            }, [])}
            renderRight={useCallback(
              ({ textProps }) => (
                <Clickable
                  testId="ageGateSave"
                  onClick={() => formApiRef.current?.submit()}
                >
                  <Text {...textProps}>{t('app.actions.save')}</Text>
                </Clickable>
              ),
              []
            )}
          />
        </Box>

        <Box padding={`0 ${paddingX} ${paddingY}`}>
          <Form<{ territory: string; age: string }>
            apiRef={formApiRef}
            onSubmit={({ values }) => {
              setAddon(AddonTypes.AGE_GATE, {
                ...addonData,
                [countryCode]: Number(values.age),
              });

              setStage('default');
            }}
          >
            <InputLabel
              value={t('itemEdit.labels.ageLimit')}
              description={t('itemEdit.ageGateAddon.ageHint')}
            >
              <AgeInput defaultValue={addonData[countryCode]?.toString()} />
            </InputLabel>
          </Form>

          <RemoveButton
            text="Remove"
            testId="ageGateRemoveTerritory"
            margin={`${paddingX} 0 0`}
            onClick={useCallback(() => {
              delete addonData[countryCode];

              setAddon(AddonTypes.AGE_GATE, addonData);

              setStage(isEmptyObject(addonData) ? 'add' : 'default', {
                params: {
                  [AGE_GATE_TERRITORY_HASH]: undefined,
                },
              });
            }, [addonData])}
          />
        </Box>
      </Box>
    );
  }
);
