import React, { useCallback } from 'react';

import { DialogBoxHeader } from '~/app/components/DialogBox/DialogBoxHeader';
import { useItemContext } from '~/app/components/ItemPage/ItemPageContext';
import { DIALOG_BOX_CONTENT_PADDING_X } from '~/app/components/DialogBox';
import { getCountryName } from '~/app/lib/utils/countriesByCode';
import ChevronIcon from '~/app/components/Icon/ChevronIcon';
import CardAction from '~/app/components/Card/CardAction';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import CountryFlag from '~/app/components/CountryFlag';
import Clickable from '~/app/components/Clickable';
import Text from '~/app/components/Text';
import { useI18n } from '~/app/lib/i18n';
import Box from '~/app/components/Box';

import { sortTerritories } from '../lib/sortTerritories';
import { AgeGateDialogRenderStage } from '../types';

import {
  AGE_GATE_TERRITORY_HASH,
  DEFAULT_TERRITORY_CODE,
  DEFAULT_TERRITORY_NAME,
} from '../constants';
import { isEmptyObject } from '~/app/lib/utils/object';

export const DefaultStage = React.memo<AgeGateDialogRenderStage>(
  ({ close, setStage }) => {
    const { addons } = useItemContext();
    const { t } = useI18n();

    const addonData = addons[AddonTypes.AGE_GATE] ?? {};

    return (
      <Box fullHeight flexColumn>
        <Box zIndex={2} style={{ position: 'sticky', top: 0 }}>
          <DialogBoxHeader
            title={t('itemEdit.ageGateAddon.title')}
            onCloseClick={close}
          />
        </Box>
        <Box tag="ul" testId="ageGateTerritories" flexGrow>
          {isEmptyObject(addonData) ? (
            <Text size="1.3rem" color="#777" padding="4rem 2rem" centered>
              {t('itemEdit.ageGateAddon.emptyList')}
            </Text>
          ) : (
            Object.entries(addonData)
              .sort(sortTerritories)
              .map(([code, age]) => {
                const isDefaultTerritory = code === DEFAULT_TERRITORY_CODE;

                return (
                  <li key={code} data-testid="ageGateTerritory">
                    <Clickable
                      data={age}
                      padding={`0 ${DIALOG_BOX_CONTENT_PADDING_X}`}
                      height="4.6rem"
                      flexRow
                      alignCenter
                      onClick={() => {
                        setStage('edit', {
                          params: {
                            [AGE_GATE_TERRITORY_HASH]: code,
                          },
                        });
                      }}
                    >
                      <CountryFlag
                        noFlexShrink
                        countryCode={code}
                        size="1.75rem"
                        margin="0.1em 0.65em 0 0"
                        saturation={0.7}
                      />
                      <Text
                        size="1.6rem"
                        margin="0 1rem 0 0"
                        isBold
                        withEllipsis
                      >
                        {isDefaultTerritory
                          ? DEFAULT_TERRITORY_NAME
                          : getCountryName(code)}
                      </Text>
                      <Text
                        size="1.6rem"
                        color="#999"
                        margin="0 1rem 0 0"
                        noWrap
                      >{`${age}+`}</Text>
                      <ChevronIcon
                        noFlexShrink
                        direction="right"
                        size="2rem"
                        margin="0 -0.5rem 0 auto"
                      />
                    </Clickable>
                  </li>
                );
              })
          )}
        </Box>
        <CardAction
          zIndex={2}
          height="4.7rem"
          withActiveStyle={false}
          text="Add another territory"
          testId="ageGateAddTerritory"
          style={{ position: 'sticky', bottom: 0 }}
          onClick={useCallback(() => {
            setStage('add', {
              params: {
                [AGE_GATE_TERRITORY_HASH]: undefined,
              },
            });
          }, [])}
        />
        <style jsx>{`
          li {
            color: #ccc;
          }

          li:hover {
            color: #fff;
          }

          li :global(svg) {
            opacity: 0.5;
          }

          li:hover :global(svg) {
            opacity: 0.7;
          }
        `}</style>
      </Box>
    );
  }
);
