import { AddonTypes } from '~/app/lib/songwhipApi/types';

export const AGE_GATE_TERRITORY_HASH = `${AddonTypes.AGE_GATE}_TERRITORY`;

export const AGE_REGEX = /^([1-9]|[1-9][0-9])$/;

export const DEFAULT_TERRITORY_CODE = 'default';
export const DEFAULT_TERRITORY_NAME = 'Default';

export const DEFAULT_TERRITORY = [
  DEFAULT_TERRITORY_CODE,
  DEFAULT_TERRITORY_NAME,
];
