import { flag } from 'country-emoji';
import { FC } from 'react';

import Text, { TextProps } from '../Text';

interface CountryFlagProps extends Omit<TextProps, 'children'> {
  countryCode: string;
  saturation?: number;
}

const CountryFlag: FC<CountryFlagProps> = ({
  countryCode,
  saturation = 0.5,
  ...textProps
}) => (
  <Text
    isInline
    {...textProps}
    style={{ filter: `grayscale(${1 - saturation})` }}
  >
    {countryCode && (flag(countryCode) || '🌍')}
  </Text>
);

export default CountryFlag;
