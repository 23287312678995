import Debug from 'debug';

import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';

import {
  AddonsConfig,
  AddonTypes,
  ItemConfig,
  ItemPageCustomLinkObject,
  ItemPageCustomObject,
} from '~/app/lib/songwhipApi/types';

import { StoredCustomPage } from '../customPages/types';
import { Dispatch, StoredItem, State } from '../types';

import {
  AddCustomObjectAction,
  EditStageActionTypes,
  RemoveAddonAction,
  RemoveCustomObjectAction,
  SetLayoutAction,
  SetLayoutSectionAction,
  UpdateCustomObjectAction,
  UpdateLayoutSectionAction,
} from './types';

const debug = Debug('songwhip/store/editStage');

export const stageItemForEditAction =
  (item: StoredItem | StoredCustomPage) => (dispatch: Dispatch) => {
    debug('stage item for edit', item);

    dispatch({
      type: EditStageActionTypes.STAGE_ITEM,
      item,
    });
  };

export const setLayoutSectionsAction =
  (params: Omit<SetLayoutSectionAction, 'type'>) => (dispatch: Dispatch) => {
    dispatch<SetLayoutSectionAction>({
      type: EditStageActionTypes.SET_LAYOUT_SECTIONS,
      ...params,
    });
  };

export const updateLayoutSectionAction =
  (params: Omit<UpdateLayoutSectionAction, 'type'>) => (dispatch: Dispatch) => {
    dispatch({
      type: EditStageActionTypes.UPDATE_LAYOUT_SECTION,
      ...params,
    });
  };

export const setLayoutAction =
  (params: Omit<SetLayoutAction, 'type'>) => (dispatch: Dispatch) => {
    dispatch({
      type: EditStageActionTypes.SET_LAYOUT,
      ...params,
    });
  };

export const setAddonAction =
  <TAddonTypes extends AddonTypes>(
    addonType: TAddonTypes,
    value: AddonsConfig[TAddonTypes] | null
  ) =>
  (dispatch: Dispatch) => {
    debug('set addon', { addonType, value });

    dispatch({
      type: EditStageActionTypes.SET_ADDON,
      addonType,
      value,
    });
  };

export const clearAddonAction =
  (addonType: AddonTypes) => (dispatch: Dispatch) => {
    debug('clear addon', addonType);

    dispatch<RemoveAddonAction>({
      type: EditStageActionTypes.CLEAR_ADDON,
      addonType,
    });
  };

export const addCustomObjectAction =
  (customObject: ItemPageCustomObject) =>
  (dispatch: Dispatch, getState: () => State) => {
    debug('add custom object', customObject);

    const state = getState();

    const id = resolveNextCustomObjectId(
      state!.editStage?.item?.config?.objects
    );

    const objectPath = `$.custom.${id}`;

    dispatch<AddCustomObjectAction>({
      type: EditStageActionTypes.ADD_CUSTOM_OBJECT,
      id,
      customObject,
    });

    return objectPath;
  };

export const updateCustomObjectAction =
  (params: Omit<UpdateCustomObjectAction, 'type'>) => (dispatch: Dispatch) => {
    dispatch<UpdateCustomObjectAction>({
      type: EditStageActionTypes.UPDATE_CUSTOM_OBJECT,
      ...params,
    });
  };

export const removeCustomObjectAction =
  (params: Omit<RemoveCustomObjectAction, 'type'>) => (dispatch: Dispatch) => {
    dispatch<RemoveCustomObjectAction>({
      type: EditStageActionTypes.REMOVE_CUSTOM_OBJECT,
      ...params,
    });
  };

export const addCustomLinkAction = (
  params: Omit<ItemPageCustomLinkObject, 'type'>
) =>
  addCustomObjectAction(
    removeUndefinedKeys({
      type: 'link',
      ...params,
    })
  );

const resolveNextCustomObjectId = (
  customLayoutObjects: ItemConfig['objects']
) => {
  if (!customLayoutObjects) return '1';

  const ids = Object.keys(customLayoutObjects);
  if (!ids.length) return '1';

  const lastId = ids[ids.length - 1];
  return String(Number(lastId) + 1);
};
