import { I18nFormatters } from '~/app/lib/i18n';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { PresetTypes } from '../../constants';
import { ItemContext, PageSectionDefinition } from '../../types';

export enum AddFeatureItemTypes {
  ADDON = 'ADDON',
  SECTION = 'SECTION',
}

interface AddFeatureItemBase {
  title: string | JSX.Element;
  description: string;
  tagType?: 'new' | 'beta';
  openSettingsAfterAdd?: boolean;

  insertAtIndex?: (params: { itemContext: ItemContext }) => number;

  onAfterAdd?: (params: {
    layoutPath: string;
    setHashParam: (values: Record<string, string>) => void;
    itemContext: ItemContext;
    props?: Record<string, any> | undefined;
  }) => void;

  shouldShow?: (params: {
    itemContext: ItemContext;
    item: AddFeatureItem;
  }) => boolean | undefined;
}

export interface AddFeatureItemSection<TSectionProps = {}>
  extends AddFeatureItemBase {
  type: AddFeatureItemTypes.SECTION;
  pageSection: PageSectionDefinition<TSectionProps>;
}

export interface AddFeatureItemAddon extends AddFeatureItemBase {
  type: AddFeatureItemTypes.ADDON;
  addonType: AddonTypes;
  defaultValue: any;
}

export type AddFeatureItem = AddFeatureItemSection | AddFeatureItemAddon;

export type ToFeatureItem<TSectionProps = {}> = (context: {
  isEnabled?: boolean;
  shareId?: PageSectionDefinition['shareId'];
  preset?: PresetTypes;
  i18n: I18nFormatters;
}) => AddFeatureItemSection<TSectionProps>;
