import AgeGateIcon from '~/app/components/Icon/AgeGateIcon';
import { AddonTypes } from '~/app/lib/songwhipApi/types';

import { AddFeatureItemTypes } from '../../AddFeatureDialog/constants';
import { Addon } from '../types';

import SettingsDialog from './AgeGateSettingsDialog';
import { DEFAULT_TERRITORY_CODE } from './constants';

const ageGateAddon: Addon = ({ i18n }) => ({
  name: i18n.t('itemEdit.ageGateAddon.title'),
  shortName: i18n.t('itemEdit.ageGateAddon.title'),
  Icon: AgeGateIcon,
  SettingsDialog,

  addFeatureItem: {
    type: AddFeatureItemTypes.ADDON,
    addonType: AddonTypes.AGE_GATE,
    title: i18n.t('itemEdit.ageGateAddon.title'),
    description: i18n.t('itemEdit.ageGateAddon.description'),

    defaultValue: {},
  },
});

export { DEFAULT_TERRITORY_CODE };
export default ageGateAddon;
