import React, { useLayoutEffect, useMemo } from 'react';

import { useItemContext } from '~/app/components/ItemPage/ItemPageContext';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { isEmptyObject } from '~/app/lib/utils/object';
import useHash from '~/app/lib/hooks/useHash';
import { useI18n } from '~/app/lib/i18n';

import DialogBoxWithStages, {
  useOpenDialog,
} from '~/app/components/DialogBoxWithStages';

import { useEditActions } from '../../useEditActions';

import { AddStage, DefaultStage, EditStage } from './components';
import { AgeGateDialogStages, AgeGateDialogRenderStage } from './types';
import { AGE_GATE_TERRITORY_HASH } from './constants';

const AgeGateSettingsDialog = React.memo(() => {
  const [, open] = useOpenDialog(AddonTypes.AGE_GATE);
  const { clearAddon } = useEditActions();
  const { addons } = useItemContext();
  const { hashParams } = useHash();
  const { t } = useI18n();

  const addonData = addons[AddonTypes.AGE_GATE] ?? {};

  // NOTE: Cause we have some infrastructure around addons dialog built for DialogBox
  // this way we can manage initial stage for the DialogBoxWithStages component
  useLayoutEffect(() => {
    if (hashParams[AddonTypes.AGE_GATE]) return;

    open(isEmptyObject(addonData) ? 'add' : 'default');
  }, []);

  return (
    <DialogBoxWithStages
      testId="ageGateSettings"
      dialogId={AddonTypes.AGE_GATE}
      fillViewportOnSmallScreen
      withOverflowGradients={false}
      maxHeight="min(46rem, 90vh)"
      onClose={() => {
        if (isEmptyObject(addonData)) {
          clearAddon(AddonTypes.AGE_GATE);
        }
      }}
      stages={useMemo(
        () =>
          ({
            default: (props) => <DefaultStage {...props} />,
            add: (props) => <AddStage {...props} />,
            edit: (props) => {
              const countryCode = hashParams[AGE_GATE_TERRITORY_HASH];

              if (!countryCode || !addonData[countryCode]) {
                throw new Error(t('itemEdit.ageGateAddon.missingTerritory'));
              }

              return <EditStage {...props} countryCode={countryCode} />;
            },
            // HACK: get the typings to work when used w/ useMemo()
          }) as Record<AgeGateDialogStages, AgeGateDialogRenderStage>,
        [hashParams, addonData]
      )}
    />
  );
});

export default AgeGateSettingsDialog;
