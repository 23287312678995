import { getCountryName } from '~/app/lib/utils/countriesByCode';

import { DEFAULT_TERRITORY_CODE } from '../constants';

// NOTE: We do not use localeCompare here cause we want Default
// territory be always first but we cannot rely on localeCompare return number
export const sortTerritories = (
  [aCountryCode]: [string, number],
  [bCountryCode]: [string, number]
) => {
  if (aCountryCode === DEFAULT_TERRITORY_CODE) return -1;

  const aCountryName = getCountryName(aCountryCode);
  const bCountryName = getCountryName(bCountryCode);

  if (aCountryName < bCountryName) return -1;
  if (aCountryName > bCountryName) return 1;

  return 0;
};
